.rmst-card {
    padding: 15px;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.participantes-transcricao {
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.card-participantes {
    min-width: 220px;
    height: fit-content;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    p {
        display: flex;
        justify-content: space-between;
        width: 165px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            cursor: pointer;
        }
    }
}

.card-resumo {
    width: 100%;
}

.card-ecotempo {
    text-align: right;
    margin-bottom: 18px;

    h2 {
        font-size: 36px;
    }

    p {
        font-size: 12px;
    }

}

.resume-text {
    font-family: 'Roboto', sans-serif;
}