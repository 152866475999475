.recommended {
    border: 4px solid #2970fa;
    border-radius: 4px;
    position: relative;

    .MuiChip-root {
        position: absolute;
        right: 12px;
        background-color: #2970fa;
    }
}

.contracted {
    position: relative;
    // border: 4px solid var(--mui-palette-success-main);

    .MuiChip-root {
        position: absolute;
        right: 12px;
    }
}

.plan-card {

    .MuiButtonBase-root {
        background-color: #2970fa;
        margin: 22px;
    }

    .alert-plan-active {
        margin: 22px;
    }
}

.pro,
.basic,
.enterprise {

    .MuiButtonBase-root {
        background-color: #2970fa;
        margin: 22px;
    }
}