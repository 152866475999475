html,
body,
#root,
h1,
h2,
h3,
h4,
h5,
h6, p {
    padding: 0;
    margin: 0;
}

html,
body,
#root {
    /* width: 100vw; */
    height: auto;
    /* min-height: calc(100vh - 50px); */
}

#root {
    /* padding-bottom: 50px; */
}
body,
p,
span,
a,
button,
input,
select,
option,
textarea,
label,
div {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito', sans-serif;
    margin-bottom: 8px;
}

#root {
    background-color: #f5f5f5;
}