.resume-header {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    height: 100px;
}

.header { 
    border-bottom: 1px solid black;
    padding-left: 120px;
    padding-right: 120px;

    @media (max-width: 992px) {
        padding-left: 18px;
        padding-right: 18px;
    }
}

.link {
    text-decoration: underline;
    color: #004C8F;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    
    img { 
        margin-right: 2px;
    }

    &:first-child {
        margin-right: 8px;
    }
}

.legend {
    font-size: 12px;
    color: #797979;
}

.header-ctas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-container {
    width: 100%;
    display: flex;
    height: 100px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.resume-header-1 {
    margin-bottom: 8px;
}

.title-transcription {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.resume-container {


    .rsmt-tab {
        text-transform: none;

        &.Mui-selected {
            color: #004C8F;
        }
    }

    .gray-container {
        background-color: #ECECEC;
        width: 100%;
        padding: 10px;
        margin-top: 15px;
    }

    .tab-content {
        padding: 20px 0;

        p {
            margin-bottom: 8px;
        }
    }

    .ask-input {
        display: flex;
        flex-direction: row;
        background-color: #ECECEE;
        height: auto;
        width: 100%;
        align-items: flex-end;
        box-sizing: border-box;
        margin-bottom: 16px;
        padding: 10px 10px;
    }

    .magic-button {
        border: none;
        font-size: 14px;
        background-image: linear-gradient(90deg, #f70b53, #0d47a1);
        height: 30px;
        padding: 0px 7px;
        width: 170px;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            @media (max-width: 992px) {
                display: none;
            }
        }

        @media (max-width: 992px) {
            justify-content: center;
        }

        @media (max-width: 768px) {
            font-size: 11px;

        }
    }

    .ask-tips {
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
            border: 1px solid #004C8F;
            border-radius: 10px;
            color: #004C8F;
            font-size: 14px;
            padding: 0px 7px;
            cursor: pointer;
            margin-left: 10px;
            min-width: 90px;
            display: flex;
            align-items: center;
        }

        @media (max-width: 768px) {
            overflow-x: auto;
        }
    }

    .answer-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 150px;
        align-items: center;

        #answer {
            width: 100%;
        }

        #answer-feedback {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .row-transcript {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 10px;
        background-color: #fff;

        .col1 {
            width: 200px;
            display: flex;
            flex-direction: column;

            span {
                font-size: 14px;
            }

            .time-message {
                font-size: 12px;
            }
        }

        .col2 {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .row-gray-transcript {
        background-color: #EEEEEE;
    }

    .avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

}


.blocked-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    height: 400px;
    justify-content: center;
}
