.download-button {
    background-color: #004C8F;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;
    color: white;
    border-radius: 50px;
    padding: 8px 16px;
    width: fit-content;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    font-family: 'Roboto', sans-serif;

    img {
        margin-left: 8px;
    }
}