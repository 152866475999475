.automation-select-component {
    width: 100%;
    
    .option-selected {
        font-size: 24px;
        color: rgb(25, 118, 210);
        text-decoration: underline;
        cursor: pointer;
    }

    .automation-select-placeholder {
        font-size: 24px;
        color: rgb(121, 126, 147);
        text-decoration: underline;
        cursor: pointer;
        animation: pulse 2s infinite;
        animation-delay: 20s;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.05);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    .automation-select-option {
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background-color: rgba(121, 126, 147, .1);
        }
    }

    .automation-select-box-options {
        border-radius: 5px;
        width: 70%;
        border: 1px solid rgba(121, 126, 147, .1);
        box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
        padding: 5px;
    }

    .automation-option-params {
        div {
            margin-bottom: 10px;
        }
    }
}

.automation-container {
    padding: 0px 40px;
}

.automation-list-item {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.automation-item-params {
    width: 100%;
    padding: 10px 10px;
}